import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { searchCache, searchInWordpress } from '../api/search';
import { SearchResults } from '../components/molecules/searchResults';
import { SearchSection } from '../components/molecules/searchSection';
import { useDebounce } from '../hooks/useDebounce';
import { PageProps } from '../types/pageProps';
import { SearchResultItem } from '../types/SearchResultItem';

// TODO: Add polyfills for Fetch and AbortController.
// TODO: Fix container to be full width of the 140rem container, so the 100vw hack is not needed.
//       if an element needs to be centered it can control it's width by itself.
const SearchPage: React.FC<PageProps> = (props) => {
  const urlQuery = (props?.location?.state as any)?.query;

  const [query, setQuery] = useState(urlQuery ?? '');
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<SearchResultItem[]>(
    searchCache.get(query) ?? []
  );

  const [debouncedQuery, setDebouncedQuery] = useDebounce(query, 500);

  useEffect(() => {
    const controller = new AbortController();
    async function search(query: string) {
      if (debouncedQuery === '') {
        setLoading(false);
        setResults([]);
        return;
      }
      setLoading(true);
      try {
        const data = await searchInWordpress(debouncedQuery, controller.signal);
        setResults(data);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }

    search(debouncedQuery);

    return () => {
      controller.abort();
    };
  }, [debouncedQuery]);

  // Search when mounted and query is set.
  // This means you navigated via the back button
  // or the top search bar
  useEffect(() => {
    if (mounted) return;
    else setMounted(true);

    setQuery(query);
    setDebouncedQuery(query);
  }, [query, mounted]);

  // TODO - should query+results remain when navigate to different page and then navigate to search?

  // Syncs the query to the state
  // This is needed if the users clicks on a result and later presses
  // the back button
  useEffect(() => {
    if (urlQuery === query) {
      return;
    }
    navigate(props.uri ?? '', {
      state: { query },
      replace: true,
    });
  }, [query]);

  return (
    <>
      <SearchSection
        onChange={setQuery}
        value={query}
        lastValue={debouncedQuery}
        isLoading={loading}
      />
      <div className="container" style={{ width: '100vw' }}>
        <SearchResults
          results={results}
          query={debouncedQuery}
          loading={loading}
        />
      </div>
    </>
  );
};

export default SearchPage;

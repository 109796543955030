import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { SearchResultItem as SearchResultItemType } from '../../../types/SearchResultItem';
import { parseLink } from '../../../utils/parseLink';
import './searchResultItem.scss';
import { Category } from '../../atoms/category';
import { decodeHTML } from '../../../utils/decodeHTML';

export interface SearchResultItemProps extends SearchResultItemType {}

export const SearchResultItem: React.FC<SearchResultItemProps> = ({
  id,
  title,
  description,
  imageUrl,
  url,
  type,
  date,
  category,
  isAdvertisement,
}) => {
  const targetUrl = parseLink(url);

  return (
    <Link to={targetUrl}>
      <div
        className={classNames('search-result-item', {
          advertisement: isAdvertisement,
        })}
      >
        <div className="search-result-item__image-wrapper">
          <div>
            <div
              className="search-result-item__image"
              style={{
                backgroundImage: 'url(' + imageUrl + ')',
              }} /* TODO: use gatsby cached */
            />
          </div>
        </div>
        <div className="search-result-item__description-wrapper">
          <div className="search-result-item__metadata">
            <span className="search-result-item__category">
              <Category>{category}</Category>
            </span>
            {category && date && <span> | </span>}
            <span>{date}</span>
          </div>
          <div className="search-result-item__title">
            <span>{decodeHTML(title)}</span>
          </div>
          <div
            className="search-result-item__content"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </Link>
  );
};

import React from 'react';
import classNames from 'classnames';
import './activityIndicator.scss';

export interface ActivityIndicatorProps {
  active?: boolean;
  // Should it be inline or in modal (absolute)
  inline?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const ModalWrapper: React.FC<{ visible?: boolean }> = ({
  visible,
  children,
}) => (
  <span
    className="activity-indicator__outer"
    style={{ display: !!visible ? 'block' : 'none' }}
  >
    {children}
  </span>
);

export const ActivityIndicator: React.FC<ActivityIndicatorProps> = React.memo(
  ({ inline = false, active = true, style = {}, className }) => {
    const Wrapper = inline ? React.Fragment : ModalWrapper;
    const wrapperProps = inline ? {} : { visible: active }; // react.Fragment cannot have any props

    return (
      <Wrapper {...wrapperProps}>
        <span
          className={classNames(
            'activity-indicator__loader',
            {
              'activity-indicator__loader--centered': !inline,
            },
            className
          )}
          style={Object.assign(
            style ?? {},
            active ? undefined : { display: 'none' }
          )}
        />
      </Wrapper>
    );
  }
);

import { format } from 'date-fns/esm';
import { SearchResponseItem } from '../types/SearchResponseItem';
import { SearchResultItem } from '../types/SearchResultItem';

export const searchCache = new Map<string, SearchResultItem[]>();

const base = process.env.GATSBY_WORDPRESS_URL;

// TMP: user&page&post has a post structure
const graphqlQuery = `
query SearchQuery($query: String!) {
  posts(where: {search: $query}) {
    nodes {
      __typename
      id
      title
      link
      excerpt
      date
      acfPostAdData {
        advertisement {
          isadvertisement
          companyname
        }
      }
      acfPostFields {
        teaserTitle
        teaserSummary
        teaserImage {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      categories(first: 1) {
        nodes {
          name
        }
      }
    }
  }
  pages(where: {search: $query}) {
    nodes {
      __typename
      id
      title
      link
      date
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
  users(where: {search: $query}) {
    nodes {
      __typename
      id
      title: name
      link: slug
      excerpt: description
      date: registeredDate
      featuredImage: avatar {
        mediaItemUrl: url
      }
    }
  }
}

`;

export const searchInWordpress = async (
  query: string,
  abortSignal: AbortSignal | null = null
): Promise<SearchResultItem[]> => {
  const result = await fetch(`${base}/graphql`, {
    method: 'post',
    signal: abortSignal,
    body: JSON.stringify({
      query: graphqlQuery,
      variables: { query },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!result.ok) return [];

  const body = await result.json();

  // TODO: Fix types and error handling
  const flattened = Object.values(body.data)
    .map((edge: any) => edge.nodes)
    .flat()
    .map((node) => processNode(node))
    // TODO: FIXME this temporary default sorting is coupled with "date" field format
    .sort((a, b) => b.date.localeCompare(a.date));

  searchCache.set(query, flattened);

  return flattened;
};

const processNode = (data: SearchResponseItem): SearchResultItem => {
  // TMP: user&page&post has a post structure
  return {
    type: data.__typename,
    id: data.id,
    title: data.acfPostFields?.teaserTitle ?? data.title,
    category: data.categories?.nodes?.[0]?.name,
    url: data.link,
    date: data.date ? format(Date.parse(data.date), 'yyyy-MM-dd') : '',
    description: data.acfPostFields?.teaserSummary ?? data.excerpt ?? '',
    imageUrl: data.acfPostFields?.teaserImage?.mediaItemUrl ?? data?.featuredImage?.node?.mediaItemUrl ?? '',
    isAdvertisement: !!data?.acfPostAdData?.advertisement?.isadvertisement,
  };
};

import React from 'react';
import classnames from 'classnames';
import './searchSection.scss';

export interface SearchSectionProps {
  value: string;
  lastValue: string;
  isLoading: boolean;
  onChange: (val: string) => void;
}

const SEARCH_INPUT_ID = 'techarenan-search-input';
const ICON_CLASSNAME_SEARCH = 'icon-magnifier-dark';
const ICON_CLASSNAME_CLEAR = 'icon-x-dark';

export const SearchSection: React.FC<SearchSectionProps> = ({
  value,
  lastValue,
  onChange,
  isLoading,
}) => {
  const showClearButton = !!value && !isLoading && lastValue === value;

  const handleButtonClick = React.useCallback(() => {
    if (showClearButton) {
      onChange('');
    }
  }, [showClearButton]);

  return (
    <section className="search-section">
      <div className="container">
        <div className="search-section__label">
          <label htmlFor={SEARCH_INPUT_ID}>Sök</label>
        </div>
        <div className="search-section__input">
          <input
            id={SEARCH_INPUT_ID}
            type="text"
            value={value}
            onChange={ev => onChange(ev.target.value)}
            placeholder="Sök artiklar, ämnen och skribenter"
          />
          <button onClick={handleButtonClick}>
            <span
              className={classnames(
                'icon',
                showClearButton ? ICON_CLASSNAME_CLEAR : ICON_CLASSNAME_SEARCH
              )}
            />
          </button>
        </div>
      </div>
    </section>
  );
};

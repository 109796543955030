import React from 'react';
import './searchResults.scss';
import { SearchResultItem as SearchResultItemType } from '../../../types/SearchResultItem';
import { SearchResultItem } from '../searchResultItem';
import {
  Separator,
  SeparatorType,
  SeparatorColor,
} from '../../atoms/separator';
import { ActivityIndicator } from '../../atoms/activityIndicator';

export type SearchResultsProps = {
  results: SearchResultItemType[];
  loading: boolean;
  query: string;
};

export const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  query,
  loading,
}) => (
  <div className="search-results">
    <ActivityIndicator active={!!loading} />
    <div className="search-results__header">
      {!!query && !loading && (
        <>
          <p className="search-results__summary">
            <span>Sökningen på </span>
            <span className="strong">{query}</span>
            <span> gav </span>
            <span>{results.length}</span>
            {results.length === 1 ? <span> träff</span> : <span> träffar</span>}
          </p>
        </>
      )}
    </div>
    <div className="search-results__content">
      {results.map(item => (
        <React.Fragment key={item.id}>
          <Separator color={SeparatorColor.Light} />
          <SearchResultItem {...item} />
        </React.Fragment>
      ))}
    </div>
  </div>
);

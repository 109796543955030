import classNames from 'classnames';
import React, { useContext } from 'react';
import { CategoriesContext } from '../../../contexts';
import './category.scss';

export type CategoryProps = {
  children: any;
  className?: string;
};

const getClassNameForColor = (color: string | null) =>
  color ? `category--${(color ?? '').toLowerCase()}` : null;

export const Category = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<CategoryProps>
>(({ children, className }, ref) => {
  const { getColorForCategory } = useContext(CategoriesContext);

  return (
    <span
      ref={ref}
      className={classNames(
        className,
        'category',
        getClassNameForColor(getColorForCategory(children) || '')
      )}
    >
      {children}
    </span>
  );
});
